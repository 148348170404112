import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import "@hotwired/turbo-rails";
import "../stylesheets/base.scss";
import { start as TurboStart } from "@hotwired/turbo";
import "controllers";
import "tippy.js/dist/tippy.css";
import { Modal, Ripple, initTE, Dropdown, Toast, Animate } from "tw-elements";
initTE({ Dropdown, Modal, Ripple, Toast, Animate });
TurboStart();
Rails.start();
ActiveStorage.start();
