var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import ApplicationController from "@/controllers/application_controller";
import "notyf/notyf.min.css";
export default class component_controller_default extends ApplicationController {
  initialize() {
  }
  connect() {
  }
  disconnect() {
  }
  exportClick() {
    const {
      status: { value: status },
      template_id: { value: template_id },
      search: { value: search }
    } = this.filterFormTarget;
    const url = new URL(this.exportUrlValue);
    url.search = new URLSearchParams({ template_id, search, status });
    Turbo.visit(url.href);
  }
}
__publicField(component_controller_default, "values", {
  exportUrl: String
});
__publicField(component_controller_default, "targets", ["filterForm"]);
