import ApplicationController from "@/controllers/application_controller";
import ChromeExt from "../../../javascript/lib/chromeExt";
export default class extends ApplicationController {
  initialize() {
  }
  connect() {
  }
  disconnect() {
  }
  handleSubmit(e) {
    if (e.detail.success) {
      ChromeExt.refetchTemplates();
    }
  }
}
