var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import ApplicationController from "@/controllers/application_controller";
import CSVFileValidator from "csv-file-validator";
export default class component_controller_default extends ApplicationController {
  initialize() {
  }
  disconnect() {
  }
  connect() {
  }
  fileValidationMessage() {
    const template = this.templateValue;
    switch (template.input_source) {
      case "youtube_url":
        return "Invalid YouTube url";
      case "audio_url":
        return "Invalid Audio url";
      default:
        return null;
    }
  }
  hideFileErrors() {
    this.fileErrorTarget.classList.add("hidden");
  }
  setFileName(file) {
    const fileName = file.name;
    this.fileNameTarget.innerHTML = fileName;
  }
  showFileErrors(data) {
    var _a;
    const baseMessage = (_a = data.find((d) => !d.rowIndex)) == null ? void 0 : _a.message;
    const validationMessage = this.fileValidationMessage();
    let message;
    if (validationMessage) {
      message = [
        this.fileValidationMessage(),
        "on rows:",
        data.map((d) => d.rowIndex).join(", ")
      ].join(" ");
    } else {
      message = data.map((d) => d.message).join("\n");
    }
    this.fileErrorTarget.innerHTML = baseMessage || message;
    this.fileErrorTarget.classList.remove("hidden");
  }
  nameFieldRequired() {
    const template = this.templateValue;
    return template.input_source !== "youtube_url";
  }
  fileHeaders() {
    const template = this.templateValue;
    const validator = this.urlValidationValue;
    return [
      {
        name: "Name",
        inputName: "name",
        required: this.nameFieldRequired(),
        validate: function(data) {
          if (template.input_source !== "youtube_url") {
            return data.length > 0;
          } else {
            return true;
          }
        }
      },
      {
        name: "Data",
        inputName: "data",
        required: true,
        validate: function(data) {
          if (validator) {
            return data.trim().match(validator);
          }
          return true;
        }
      }
    ];
  }
  onCsvUpload(e) {
    const config = { headers: this.fileHeaders() };
    const file = e.target.files[0];
    CSVFileValidator(file, config).then((csvData) => {
      if (csvData.inValidData.length > 0) {
        e.target.value = "";
        this.showFileErrors(csvData.inValidData);
      } else {
        this.hideFileErrors();
        file && this.setFileName(file);
        this.parentController(this.parentControllerValue).submitButtonTarget.click();
      }
    }).catch((err) => {
      console.log(err, "=======err======");
    });
  }
  sampleCSVClick(e) {
    e.preventDefault();
    Turbo.visit(this.sampleCsvUrlValue);
  }
}
__publicField(component_controller_default, "values", {
  sampleCsvUrl: String,
  template: Object,
  urlValidation: String,
  parentController: String
});
__publicField(component_controller_default, "targets", ["fileError", "fileName"]);
