import tippy from "tippy.js";
const Tooltip = {
  attachToElement: ({ element, content, position = "top", enabled = true }) => {
    const parser = new DOMParser();
    const dom = parser.parseFromString(
      "<!doctype html><body>" + content,
      "text/html"
    );
    const parsedContent = dom.body.textContent;
    const instance = tippy(element, {
      content: parsedContent,
      aria: {
        content: "labelledby"
      },
      placement: position,
      interactive: true,
      allowHTML: true
    });
    if (!enabled) {
      instance.disable();
    }
    return instance;
  }
};
export default Tooltip;
