var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import ApplicationController from "@/controllers/application_controller";
import debounce from "lodash.debounce";
export default class component_controller_default extends ApplicationController {
  initialize() {
    this.submit = debounce(this.submit.bind(this), 300);
  }
  containerElement(target) {
    return target.closest(".turbo-search-container");
  }
  connect() {
    if (Boolean(this.inputTarget.value.length)) {
      this.clearTarget.classList.remove("hidden");
    }
  }
  clearInput() {
    this.inputTarget.value = "";
    this.submit();
  }
  toggleClearIcon() {
    if (Boolean(this.inputTarget.value.length)) {
      this.clearTarget.classList.remove("hidden");
    } else {
      this.clearTarget.classList.add("hidden");
    }
  }
  submit() {
    this.toggleClearIcon();
    this.element.requestSubmit();
  }
  focusout(e) {
    const container = this.containerElement(e.target);
    container.classList.remove("border-secondary-500", "text-secondary-500");
    container.querySelector(".search-icon").classList.remove("text-secondary-500");
    container.querySelector(".search-icon").classList.add("text-gray-500");
    if (this.hasSelectTarget) {
      this.selectTargets.forEach(
        (target) => target.classList.remove("border-secondary-500", "!text-secondary-500")
      );
    }
  }
  focusin(e) {
    const container = this.containerElement(e.target);
    container.classList.add("border-secondary-500", "text-secondary-500");
    container.querySelector(".search-icon").classList.add("text-secondary-500");
    container.querySelector(".search-icon").classList.remove("text-gray-500");
    if (this.hasSelectTarget) {
      this.selectTargets.forEach(
        (target) => target.classList.add("border-secondary-500", "!text-secondary-500")
      );
    }
  }
}
__publicField(component_controller_default, "targets", ["clear", "input", "select"]);
