import ApplicationController from "@/controllers/application_controller";
export default class extends ApplicationController {
  initialize() {
  }
  connect() {
  }
  disconnect() {
  }
  onSuccessSubmit() {
  }
}
