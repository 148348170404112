var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import ApplicationController from "@/controllers/application_controller";
export default class component_controller_default extends ApplicationController {
  initialize() {
  }
  disconnect() {
  }
  connect() {
    this.element.setAttribute(
      "data-turbo-form-validation-schema-value",
      JSON.stringify(this.validationSchema())
    );
  }
  handleSubmit(e) {
    this.redirectPathValue && e.detail.success && Turbo.visit(this.redirectPathValue);
  }
  validationSchema() {
    const template = this.templateValue;
    const validationMessage = () => {
      switch (template.input_source) {
        case "youtube_url":
          return "is invalid. Should be a valid YouTube video url";
        case "audio_url":
          return `is invalid. Supported audio formats are: ${this.audioFormatsValue.join(
            ", "
          )}`;
        default:
          return null;
      }
    };
    const response = {};
    if (this.urlValidationValue) {
      response.url = {
        format: {
          pattern: `^$|${this.urlValidationValue}`,
          message: validationMessage()
        }
      };
    }
    return response;
  }
}
__publicField(component_controller_default, "values", {
  audioFormats: Array,
  template: Object,
  redirectPath: String,
  urlValidation: String,
  fileSizeLimit: String
});
__publicField(component_controller_default, "targets", ["submitButton", "filesInfo", "fileInfoExample"]);
