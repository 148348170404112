var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import ApplicationController from "@/controllers/application_controller";
export default class component_controller_default extends ApplicationController {
  initialize() {
  }
  connect() {
  }
  closeDropdown() {
    this.dropdownTarget.classList.add("hidden");
    this.overlayTarget.classList.add("hidden");
  }
  toggleDropdown() {
    this.dropdownTarget.classList.toggle("hidden");
    this.overlayTarget.classList.toggle("hidden");
  }
}
__publicField(component_controller_default, "targets", ["dropdown", "overlay"]);
