import ApplicationController from "@/controllers/application_controller";
import { StreamActions } from "@hotwired/turbo";
import "notyf/notyf.min.css";
import { toastInstance } from "../lib/toast";
export default class extends ApplicationController {
  initialize() {
  }
  showToast({ options, href }) {
    setTimeout(() => {
      const notification = toastInstance().open(options);
      if (href) {
        notification.on("click", (_props) => {
          window.location.href = href;
        });
      }
    }, 500);
  }
  connect() {
    const controllerCtx = this;
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("toast_message")) {
      const toastType = urlParams.get("toast_type") || "success";
      controllerCtx.showToast({
        options: { message: urlParams.get("toast_message"), type: toastType }
      });
    }
    StreamActions.toast = function() {
      const type = this.getAttribute("type");
      const href = this.getAttribute("href");
      const options = {
        type,
        dismissible: true,
        message: this.getAttribute("message")
      };
      controllerCtx.showToast({ options, href });
    };
  }
}
