var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import ApplicationController from "@/controllers/application_controller";
export default class component_controller_default extends ApplicationController {
  initialize() {
  }
  parentController() {
    const parent = this.element.closest(
      `[data-controller~='${this.parentControllerValue}']`
    );
    return this.application.getControllerForElementAndIdentifier(
      parent,
      this.parentControllerValue
    );
  }
  onUploadClick(e) {
    e.preventDefault();
    this.fileInputTarget.click();
  }
  onFileUpload(e) {
    var _a;
    const callback = (_a = this.parentController(this.parentControllerValue)[this.onUploadCallbackValue]) == null ? void 0 : _a.bind(this.parentController());
    if (callback) {
      callback(e);
    }
  }
}
__publicField(component_controller_default, "values", {
  parentController: String,
  onUploadCallback: String
});
__publicField(component_controller_default, "targets", ["fileInput"]);
