var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import ApplicationController from "@/controllers/application_controller";
export default class component_controller_default extends ApplicationController {
  initialize() {
  }
  connect() {
    this.setIconOption();
  }
  disconnect() {
  }
  setIconOption() {
    const icon = this.selectedOption().dataset.icon;
    if (icon && this.hasIconTarget) {
      this.iconTarget.innerHTML = icon;
      this.iconTarget.classList.remove("hidden");
    } else {
      this.iconTarget.innerHTML = null;
      this.iconTarget.classList.add("hidden");
    }
  }
  selectedOption() {
    const selectedIndex = this.selectTarget.selectedIndex;
    return this.selectTarget.options[selectedIndex];
  }
  onChange() {
    this.setIconOption();
  }
}
__publicField(component_controller_default, "targets", ["select", "icon"]);
__publicField(component_controller_default, "values", {});
