var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import ApplicationController from "@/controllers/application_controller";
import { makeRequest } from "../../../javascript/lib/requests";
export default class component_controller_default extends ApplicationController {
  initialize() {
  }
  connect() {
  }
  disconnect() {
  }
  onConnect(e) {
    const form = this.element.closest("form");
    form.submit();
  }
  onDelete(e) {
    e.preventDefault();
    const confirm = window.confirm("Are you sure?");
    if (confirm) {
      makeRequest({
        url: this.urlValue,
        method: "PATCH",
        body: {
          user: {
            organization_attributes: {
              id: this.organizationIdValue,
              [this.fieldValue]: null
            }
          }
        }
      }).then((data) => {
        Turbo.visit("");
      });
    }
  }
}
__publicField(component_controller_default, "values", { url: String, field: String, organizationId: String });
