import * as te from "tw-elements";
import ApplicationController from "@/controllers/application_controller";
export default class extends ApplicationController {
  initialize() {
    this.modalContainer = document.getElementById("modalContainer");
  }
  connect() {
    document.addEventListener("closeModal", this.closeModal.bind(this));
    if (this.modalContainer && !this.modalContainer.dataset.teOpen) {
      const modal = new te.Modal(this.modalContainer, {});
      this.modalContainer.addEventListener(
        "hidden.te.modal",
        this.onModalClosed.bind(this)
      );
      modal.show();
    }
  }
  disconnect() {
    this.modalContainer.removeEventListener(
      "hidden.te.modal",
      this.onModalClosed.bind(this)
    );
    document.removeEventListener("closeModal", this.closeModal.bind(this));
  }
  onModalClosed() {
    var _a;
    document.getElementById("modal").innerHTML = "";
    (_a = this.modalContainer) == null ? void 0 : _a.removeAttribute("data-te-open");
  }
  closeModal(e) {
    e.preventDefault();
    const modal = te.Modal.getInstance(this.modalContainer);
    modal == null ? void 0 : modal.hide();
    this.onModalClosed();
  }
}
