var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import ApplicationController from "@/controllers/application_controller";
import { toastInstance } from "../../../javascript/lib/toast";
import Tooltip from "../../../javascript/lib/tooltip";
export default class component_controller_default extends ApplicationController {
  initialize() {
  }
  connect() {
    Tooltip.attachToElement({
      element: this.iconTarget,
      content: "Copy to clipboard",
      position: "left"
    });
  }
  disconnect() {
  }
  copyData() {
    navigator.clipboard.writeText(this.dataToCopyValue);
    toastInstance().open({ type: "success", message: "Copied to clipboard" });
  }
}
__publicField(component_controller_default, "targets", ["icon"]);
__publicField(component_controller_default, "values", {
  dataToCopy: String
});
