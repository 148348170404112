var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import ApplicationController from "@/controllers/application_controller";
import ChromeExtension from "../../../javascript/lib/chromeExt";
export default class component_controller_default extends ApplicationController {
  initialize() {
  }
  connect() {
  }
  disconnect() {
  }
  onSubmit(e) {
    ChromeExtension.refetchTemplates();
  }
  onUseAssemblyChange(e) {
    const checked = e.target.checked;
    if (checked) {
      this.speakerLabelsTarget.removeAttribute("disabled");
      this.speakerLabelsTarget.closest(".input-wrapper").classList.remove("hidden");
    } else {
      this.speakerLabelsTarget.checked = false;
      this.speakerLabelsTarget.setAttribute("disabled", "");
      this.speakerLabelsTarget.closest(".input-wrapper").classList.add("hidden");
    }
  }
  maxInstructionsCount() {
    const { max_instructions } = this.planDataValue;
    if (max_instructions === "unlimited") {
      return this.hardMaxInstructionsValue;
    } else {
      return Math.min(this.hardMaxInstructionsValue, max_instructions);
    }
  }
  hideMediaCheckboxes() {
    this.useAssemblyaiTarget.checked = false;
    this.speakerLabelsTarget.checked = false;
    this.mediaCheckboxesContainerTarget.classList.add("hidden");
  }
  showMediaCheckboxes() {
    if (this.useAssemblyaiTarget.checked) {
      this.speakerLabelsTarget.removeAttribute("disabled");
      this.speakerLabelsTarget.closest(".input-wrapper").classList.remove("hidden");
    } else {
      this.speakerLabelsTarget.setAttribute("disabled", "");
      this.speakerLabelsTarget.checked = false;
    }
    this.mediaCheckboxesContainerTarget.classList.remove("hidden");
  }
  handleImageInputSourceSelected(e) {
    this.modelTargets.forEach((modelTarget) => {
      modelTarget.closest(".modelWrapper").classList.add("hidden");
      modelTarget.value = this.imageProcessingModelValue;
      modelTarget.removeAttribute("required");
    });
    this.childInstructionTargets.forEach((element) => {
      const controller = this.childController(element);
      controller.updateModelConfig(this.imageProcessingModelValue);
    });
    this.hideMediaCheckboxes();
  }
  handleMediaInputSourceSelected(e) {
    if (this.requiresAssemblySourcesValue.includes(e.target.value)) {
      this.useAssemblyaiTarget.checked = true;
      this.useAssemblyaiTarget.closest(".input-wrapper").classList.add("hidden");
    } else {
      this.useAssemblyaiTarget.checked = false;
      this.useAssemblyaiTarget.closest(".input-wrapper").classList.remove("hidden");
    }
    this.showMediaCheckboxes();
  }
  onInputSourceChanged(e) {
    if (this.imageInputSourcesValue.includes(e.target.value)) {
      this.handleImageInputSourceSelected(e);
    } else if (this.mediaInputSourcesValue.includes(e.target.value)) {
      this.showModelSelector();
      this.handleMediaInputSourceSelected(e);
    } else {
      this.showModelSelector();
      this.hideMediaCheckboxes();
    }
  }
  showModelSelector() {
    this.modelTargets.forEach((modelTarget) => {
      modelTarget.setAttribute("required", "");
      modelTarget.closest(".modelWrapper").classList.remove("hidden");
    });
  }
  activeInstructions() {
    return this.childInstructionTargets.filter((element) => {
      return !element.classList.contains("hidden");
    });
  }
  disableDeleteInstructionButton() {
    this.deleteInstructionButtonTargets.forEach((element) => {
      element.setAttribute("disabled", true);
    });
  }
  enableDeleteInstructionButton() {
    this.deleteInstructionButtonTargets.forEach((element) => {
      element.removeAttribute("disabled");
    });
  }
  enableAddInstructionButton() {
    this.addInstructionButtonTarget.removeAttribute("disabled");
    this.addInstructionButtonTarget.classList.remove("hidden");
    const tooltip = this.childController(this.addInstructionTooltipTarget);
    tooltip.disabledValue = true;
    tooltip.reload();
  }
  disableAddInstructionButton() {
    if (this.activeInstructions().length >= this.hardMaxInstructionsValue) {
      this.addInstructionButtonTarget.classList.add("hidden");
    } else {
      this.addInstructionButtonTarget.setAttribute("disabled", true);
      const tooltip = this.childController(this.addInstructionTooltipTarget);
      tooltip.disabledValue = false;
      tooltip.reload();
    }
  }
  onAddInstructionClick(e) {
    const controller = this.childController(this.nestedFormTarget, { controller: "nested-form" });
    controller.add(e);
    this.onInstructionsCountChanged();
  }
  onInstructionsCountChanged() {
    const activeInstructions = this.activeInstructions();
    if (activeInstructions.length === 1) {
      this.disableDeleteInstructionButton();
    }
    if (activeInstructions.length >= 1) {
      if (activeInstructions.length > 1)
        this.enableDeleteInstructionButton();
      if (activeInstructions.length >= this.maxInstructionsCount()) {
        this.disableAddInstructionButton();
      } else {
        this.enableAddInstructionButton();
      }
    }
  }
}
__publicField(component_controller_default, "values", {
  modelsConfig: Object,
  planData: Object,
  hardMaxInstructions: Number,
  imageInputSources: Array,
  mediaInputSources: Array,
  requiresAssemblySources: Array,
  imageProcessingModel: String
});
__publicField(component_controller_default, "targets", [
  "childInstruction",
  // child fields
  "tokensRange",
  "temperatureRange",
  "model",
  "deleteInstructionButton",
  "speakerLabels",
  "useAssemblyai",
  "mediaCheckboxesContainer",
  "addInstructionTooltip",
  "addInstructionButton",
  "instructionTemplate",
  "newInstructionTarget",
  "nestedForm"
]);
