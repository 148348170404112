var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import ApplicationController from "@/controllers/application_controller";
import Tooltip from "../../../../../javascript/lib/tooltip";
export default class component_controller_default extends ApplicationController {
  initialize() {
  }
  connect() {
    this.tooltipInstance = Tooltip.attachToElement({
      element: this.rangeSliderTarget,
      content: this.maxAllowedMessageValue || "To use more upgrade plan."
    });
    this.tooltipInstance.disable();
    this.updateCurrentValue(this.rangeSliderTarget.value);
  }
  disconnect() {
  }
  refreshMaxValueText() {
    this.maxValueTextTarget.innerHTML = this.rangeSliderTarget.max;
  }
  updateCurrentValue(value) {
    this.currentValueTarget.innerHTML = value;
    this.refreshMaxValueText();
  }
  handleChange(e) {
    let current = e.target.value;
    if (this.maxAllowedValue > 0 && parseInt(current) > this.maxAllowedValue) {
      this.tooltipInstance.enable();
      this.tooltipInstance.show();
      this.rangeSliderTarget.value = this.maxAllowedValue;
      current = this.maxAllowedValue;
    } else {
      this.tooltipInstance.disable();
    }
    this.updateCurrentValue(current);
  }
}
__publicField(component_controller_default, "targets", ["rangeSlider", "currentValue", "maxValueText"]);
__publicField(component_controller_default, "values", {
  maxAllowed: Number | null,
  maxAllowedMessage: String
});
