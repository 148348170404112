const defaultTheme = require("tailwindcss/defaultTheme")
const colors = require("tailwindcss/colors")

const overrideColors = {
  primary: {
    200: "#FFE8E5",
    300: "#FADBD7",
    500: "#FF715B",
    600: "#F5654E",
  },
  secondary: {
    200: "#E7E3FF",
    300: "#DAD4FA",
    500: "#6B4FFF",
    600: "#5F42F5",
  },
  gray: {
    100: "#FAFAFC",
    200: "#F0F1F5",
    300: "#E6E7EB",
    400: "#F5F5FA",
    500: "#898DA3",
    600: "#9A9FB8",
  },
}

const spacingOverride = {
  xsmall: "8px",
  small: "16px",
  normal: "24px",
  default: "32px",
  large: "48px",
  xlarge: "64px",
}

const overrideWidth = {
  desktop: "1200px",
}

module.exports = {
  mode: "jit",
  corePlugins: {
    flex: true,
  },
  purge: [
    "./app/javascript/**/*.js",
    "./app/views/**/*.{rb,erb,haml,html,slim}",
    "./app/components/**/*.{rb,erb,haml,html,slim,js}",
    "./node_modules/tw-elements/dist/js/**/*.js",
  ],
  theme: {
    extend: {
      gap: spacingOverride,
      margin: spacingOverride,
      padding: spacingOverride,
      p: spacingOverride,
      px: spacingOverride,
      py: spacingOverride,
      pl: spacingOverride,
      pr: spacingOverride,
      minWidth: overrideWidth,
      width: overrideWidth,
      fontFamily: {
        circular: ["Circular Std", ...defaultTheme.fontFamily.sans],
        sans: ["Inter var", ...defaultTheme.fontFamily.sans],
      },
      colors: {
        ...colors,
        black: "#070914",
        transparent: "transparent",
        primary: overrideColors.primary,
        secondary: overrideColors.secondary,
        success: overrideColors.primary,
        error: overrideColors.primary,
        info: colors.amber,
        gray: overrideColors.gray,
      },
    },
  },
  plugins: [
    require("@tailwindcss/typography"),
    require("tw-elements/dist/plugin.cjs"),
    require("@tailwindcss/forms")({
      strategy: "class",
    }),
  ],
}
