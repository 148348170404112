var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import ApplicationController from "@/controllers/application_controller";
export default class component_controller_default extends ApplicationController {
  initialize() {
  }
  connect() {
  }
  disconnect() {
  }
  tabClicked(event) {
    this.tabTargets.forEach((tab) => {
      tab.classList.remove(...this.activeTabClasses);
      tab.classList.add(...this.inactiveTabClasses);
    });
    event.currentTarget.classList.add(...this.activeTabClasses);
    event.currentTarget.classList.remove(...this.inactiveTabClasses);
    const tabId = event.currentTarget.getAttribute("id");
    this.contentTargets.forEach((content) => {
      if (content.getAttribute("id") === tabId) {
        content.classList.remove("hidden");
      } else {
        content.classList.add("hidden");
      }
    });
  }
}
__publicField(component_controller_default, "classes", ["activeTab", "inactiveTab"]);
__publicField(component_controller_default, "targets", ["tab", "content"]);
