import {
  ApplicationController as ApplicationControllerStimulusUse,
  useApplication
} from "stimulus-use";
export default class extends ApplicationControllerStimulusUse {
  initialize() {
  }
  connect() {
    useApplication(this);
    document.addEventListener(
      "turbo:frame-missing",
      this.onTurboFrameMissing.bind(this)
    );
  }
  disconnect() {
    document.removeEventListener(
      "turbo:frame-missing",
      this.onTurboFrameMissing.bind(this)
    );
  }
  onTurboFrameMissing(event) {
    const {
      detail: { response, visit }
    } = event;
    event.preventDefault();
    visit(response);
  }
  childController(element, { controller } = {}) {
    var _a;
    return this.application.getControllerForElementAndIdentifier(
      element,
      controller || ((_a = element.dataset) == null ? void 0 : _a.controller)
    );
  }
  parentController(controllerName) {
    const parent = this.element.closest(`[data-controller~='${controllerName}']`);
    return this.application.getControllerForElementAndIdentifier(
      parent,
      controllerName
    );
  }
}
