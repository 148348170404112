import { Notyf } from "notyf";
const tailwind = require("../../../config/tailwind.config");
const colors = tailwind.theme.extend.colors;
export const toastInstance = () => {
  if (document.getElementsByClassName("notyf").length === 0) {
    window.toast = new Notyf({
      duration: 7e3,
      dismissible: true,
      ripple: true,
      position: {
        x: "left",
        y: "bottom"
      },
      types: [
        {
          type: "warning",
          background: colors.info["400"],
          icon: {
            className: "fa-solid fa-info !text-white",
            tagName: "i"
          }
        },
        {
          type: "success",
          background: colors.secondary["500"]
        },
        {
          type: "error",
          background: colors.error["500"]
        }
      ]
    });
  }
  return window.toast;
};
