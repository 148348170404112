var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import ApplicationController from "@/controllers/application_controller";
export default class component_controller_default extends ApplicationController {
  initialize() {
  }
  connect() {
  }
  disconnect() {
  }
  clickPlan(e) {
    return __async(this, null, function* () {
      e.target.setAttribute("disabled", "disabled");
      const planId = e.target.dataset.planId;
      const url = yield fetch(this.checkoutUrlValue + `?plan_id=${planId}`, {
        headers: { accept: "application/json" }
      }).then((response) => response.json()).then((response) => response.response.url);
      location.href = url;
    });
  }
}
__publicField(component_controller_default, "values", {
  checkoutUrl: String
});
