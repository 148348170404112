var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import ApplicationController from "@/controllers/application_controller";
export default class component_controller_default extends ApplicationController {
  initialize() {
  }
  disconnect() {
  }
  connect() {
    this.refreshModelConfig(this.modelTarget.value);
  }
  onRemoveInstructionClick(e) {
    const controller = this.parentController(this.parentControllerValue);
    this.element.classList.add("hidden");
    this.destroyInstructionTarget.value = true;
    controller.onInstructionsCountChanged();
  }
  setRangeMaxValue(target, value) {
    if (target.value > value) {
      target.value = value;
    }
    target.setAttribute("max", value);
    target.dispatchEvent(new Event("input"));
  }
  refreshModelConfig(model) {
    const config = this.modelsConfigValue[model];
    this.setRangeMaxValue(this.temperatureRangeTarget, config.max_temperature);
    this.setRangeMaxValue(this.tokensRangeTarget, config.max_tokens);
  }
  setMaxTokensToMiddle(model) {
    const config = this.modelsConfigValue[model];
    const currentMaxTokensVal = parseInt(this.tokensRangeTarget.value);
    const newMaxTokensVal = config.max_tokens;
    const middle = Math.floor(config.max_tokens / 2);
    if (currentMaxTokensVal < middle || currentMaxTokensVal === newMaxTokensVal) {
      this.tokensRangeTarget.value = middle;
      this.tokensRangeTarget.dispatchEvent(new Event("input"));
    }
  }
  updateModelConfig(model) {
    this.refreshModelConfig(model);
    this.setMaxTokensToMiddle(model);
  }
  onModelChange(e) {
    const model = e.target.value;
    this.updateModelConfig(model);
  }
}
__publicField(component_controller_default, "values", {
  modelsConfig: Object,
  parentController: String
});
__publicField(component_controller_default, "targets", [
  "tokensRange",
  "temperatureRange",
  "model",
  "destroyInstruction"
]);
