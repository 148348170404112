var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
export const extensionId = document.body.getAttribute("data-chrome-ext-id");
const ChromeExtension = {
  sendExtEvent: ({ type, message }) => {
    return new Promise((resolve) => {
      if (!window.chrome || !window.chrome.runtime) {
        resolve(null);
      } else {
        window.chrome.runtime.sendMessage(
          extensionId,
          { type, message },
          (response) => resolve(response)
        );
      }
    });
  },
  checkInstalled: () => ChromeExtension.sendExtEvent({ type: "checkInstalled" }).then(({ success }) => success).catch(() => false),
  refetchTemplates: () => __async(void 0, null, function* () {
    return (yield ChromeExtension.checkInstalled()) && ChromeExtension.sendExtEvent({ type: "refetchTemplates" });
  })
};
export default ChromeExtension;
