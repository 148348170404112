var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import ApplicationController from "@/controllers/application_controller";
import { makeRequest } from "../../../javascript/lib/requests";
export default class component_controller_default extends ApplicationController {
  initialize() {
  }
  connect() {
  }
  disconnect() {
  }
  togglePin(e) {
    const element = e.target.closest("button");
    const url = element.dataset.href;
    e.preventDefault();
    makeRequest({
      url,
      method: "PUT",
      body: {
        processing_template: {
          pinned: !this.pinnedValue
        }
      }
    }).then((data) => {
      Turbo.visit("");
    });
  }
}
__publicField(component_controller_default, "values", {
  pinned: Boolean
});
