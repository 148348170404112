var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import ApplicationController from "@/controllers/application_controller";
import EventDispatcher from "../lib/eventDispatcher";
export default class turbo_form_controller_default extends ApplicationController {
  initialize() {
  }
  connect() {
  }
  removeErrors(element) {
    const inputWrapper = element.closest(".input-wrapper");
    inputWrapper.classList.remove("error-input");
    inputWrapper.querySelector(".error-message").classList.add("hidden");
  }
  setError(element, errors) {
    const inputWrapper = element.closest(".input-wrapper");
    inputWrapper.classList.add("error-input");
    inputWrapper.querySelector(".error-message").innerHTML = errors.join(", ");
    inputWrapper.querySelector(".error-message").classList.remove("hidden");
  }
  validate() {
    let hasErrors = false;
    if (this.hasValidationSchemaValue) {
      Array.from(this.element.elements).forEach((element) => {
        const fieldName = element.dataset["validatorAnchor"];
        if (this.validationSchemaValue[fieldName]) {
          const validationErrors = validate(
            { [fieldName]: element.value },
            this.validationSchemaValue
          );
          if (validationErrors && validationErrors[fieldName]) {
            hasErrors = true;
            this.setError(element, validationErrors[fieldName]);
          } else {
            this.removeErrors(element);
          }
        }
      });
      return !hasErrors;
    } else {
      return true;
    }
  }
  submitStart(e) {
    if (this.validate()) {
      if (this.hasSubmitButtonTarget) {
        this.submitButtonTarget.setAttribute("disabled", true);
        this.submitButtonTarget.classList.add("disabled");
      }
    } else {
      e.detail.formSubmission.stop();
    }
  }
  handleSubmit(e) {
    if (this.hasSubmitButtonTarget && e.detail.success) {
      if (this.closeModalOnSuccessValue)
        EventDispatcher.closeModal();
      setTimeout(() => {
        this.submitButtonTarget.removeAttribute("disabled");
        this.submitButtonTarget.classList.remove("disabled");
        if (this.redirectAfterSuccessValue) {
          Turbo.visit(this.redirectAfterSuccessValue);
        }
      }, 500);
    }
  }
}
__publicField(turbo_form_controller_default, "targets", ["submitButton"]);
__publicField(turbo_form_controller_default, "values", {
  validationSchema: Object,
  redirectAfterSuccess: String,
  closeModalOnSuccess: { type: Boolean, default: true }
});
